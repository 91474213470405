import { Alert, DialogContent } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import * as apiUtility from '../../config/APIUtils';
import Warning from '@mui/icons-material/Warning';
import { FloatingLabel, Form, Modal, Row } from 'react-bootstrap';


function HomeRoleForUser({ open, onSubmit, onClose, userId }) {

    const [homeRoleList, setHomeRoleList] = useState({});
    const [alert, setAlert] = useState(false);
    const [homeId, setHomeId] = useState("0");
    const [roleId, setRoleId] = useState("0");
    const [status, setStatus] = useState("");

    const [homeName, setHomeName] = useState("");
    const [roleName, setRoleName] = useState("");
    
    const [roleList, setRoleList] = useState([]);
    
    var homeRoleMap = {};

    const BASE_GET_USER_HOME_ROLE = "user/fetchAllHomeRolesForUser/";
    useEffect(() => {
        setAlert(false);
        setHomeId("0");
        setHomeName("");
        setRoleId("0"); 
        homeRoleMap = {};
        let apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = BASE_GET_USER_HOME_ROLE + userId;
        apiUtility.fetchAPIDataGET(apiRequest).then(response => {
            setHomeRoleList(response.data);
        }).catch((error) => console.error(error));;
    }, [open]);
   
    const handleSubmit = (role, home, status) => {
        setHomeId(home);
        setRoleId(role);
        setStatus(status);
        homeRoleMap["homeId"] = home;
        homeRoleMap["roleId"] = role;
        homeRoleMap["homeName"] = homeName;
        homeRoleMap["roleName"] = roleName;

        if (status === "Active") {
            onSubmit(homeRoleMap);
        } else {
            setAlert(true);
        }
    };

    const alertSubmit = () => {
        homeRoleMap["homeId"] = homeId;
        homeRoleMap["roleId"] = roleId;
        homeRoleMap["homeName"] = homeName;
        homeRoleMap["roleName"] = roleName;
        onSubmit(homeRoleMap);
    };


    const filterRole = e => {
        const { name, value } = e.target
        setHomeId(value);
        setAlert(false);
        setHomeName("");
        setRoleName("");
        setRoleId("0");
        if (homeId !== "0" || homeId !== 0) {
            setRoleList(homeRoleList[value]);
        }
    };

    const prepareRequest = e => {
        const { name, value } = e.target
        setRoleId(value);
        setAlert(false);
        
        let roleSelectedStatus = "";
        for (const a of roleList) {
            if(a.roleId === value){
                setHomeName(a.homeName);
                setRoleName(a.roleName);
                roleSelectedStatus = a.status;
            }
        }
        if (homeId !== "0" && value !== "0") {
            handleSubmit(value, homeId, roleSelectedStatus);
        }
    };

    return (
        <Modal show={open} style={{ "width": "40pc", "top": "20%", "left": "28%" }} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    All Courses
                </Modal.Title>
            </Modal.Header>
            <Modal.Body layout={'center'}>

                {/* <DialogTitle>All Homes List</DialogTitle> */}
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Row style={{ padding: "1pc" }}>
                            <FloatingLabel
                                controlId="floatingHome"
                                label="&nbsp;&nbsp;&nbsp;Home"

                            >
                                <Form.Select aria-label="Floating label select example" onChange={filterRole} value={homeId} >
                                    <option value="0"> -- Please select home --  </option>
                                    {Object.entries(homeRoleList)?.map(([key, val]) => (
                                        <option value={key} key={key}>{val[0].homeName}</option>
                                    ))}
                                </Form.Select>
                            </FloatingLabel>
                        </Row>
                        <Row style={{ padding: "1pc" }}>
                            <FloatingLabel
                                controlId="floatingSelectGrid"
                                label="&nbsp;&nbsp;&nbsp;Role"
                            >
                                <Form.Select aria-label="Floating label select example" onChange={prepareRequest} value={roleId}>
                                    <option value="0"> -- Please select role --  </option>
                                    {roleList?.map((value, index) => (
                                        <option status={value.status} value={value.roleId} key={value.roleId}>{value.roleName}</option>
                                    ))}
                                </Form.Select>
                            </FloatingLabel>
                        </Row>
                        {/* <Typography level="body-sm" sx={{ textAlign: 'center', pb: 2 }}>
                                ← Scroll direction →
                            </Typography> */}
                        {/* <br></br>
                        <Sheet
                            variant="outlined"
                            sx={(theme) => ({
                                '--TableCell-height': '40px',
                                // the number is the amount of the header rows.
                                '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
                                '--Table-firstColumnWidth': '180px',
                                '--Table-lastColumnWidth': '80px',
                                // background needs to have transparency to show the scrolling shadows
                                '--TableRow-stripeBackground': 'rgba(0 0 0 / 0.04)',
                                '--TableRow-hoverBackground': 'rgba(0 0 0 / 0.08)',
                                overflow: 'auto',
                                background: `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
                                linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
                                radial-gradient(
                                farthest-side at 0 50%,
                                rgba(0, 0, 0, 0.12),
                                rgba(0, 0, 0, 0)
                                ),
                                radial-gradient(
                                    farthest-side at 100% 50%,
                                    rgba(0, 0, 0, 0.12),
                                    rgba(0, 0, 0, 0)
                                )
                                0 100%`,
                                backgroundSize:
                                    '40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))',
                                backgroundRepeat: 'no-repeat',
                                backgroundAttachment: 'local, local, scroll, scroll',
                                backgroundPosition:
                                    'var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)',
                                backgroundColor: 'background.surface',
                                height: 300
                            })}
                        >
                            <Table
                                borderAxis="both"

                                hoverRow
                                stickyHeader
                                sx={{
                                    '& tr > *:first-child': {
                                        position: 'sticky',
                                        left: 0,
                                        boxShadow: '1px 0 var(--TableCell-borderColor)',
                                        bgcolor: 'background.surface',
                                    },
                                    '& tr > *:last-child': {
                                        position: 'sticky',
                                        right: 0,
                                        bgcolor: 'var(--TableCell-headBackground)',
                                    },
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: 'var(--Table-firstColumnWidth)' }}>Home</th>
                                        <th className="text-center"  style={{ width: 200 }}>Role</th>
                                        <th className="text-center" style={{ width: 100 }}>Status</th>
                                        <th
                                            className="text-center" 
                                            aria-label="last"
                                            style={{ width: 'var(--Table-lastColumnWidth)' }}
                                        >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(homeRoleList)?.map(([key, val]) => (

                                        val?.map((value, index) => (
                                            <tr key={key + value.roleId}>

                                                {index === 0 && (
                                                    <td 
                                                    style={{
                                                        position: 'sticky',
                                                        left: 0, // Sticky on the left side
                                                        backgroundColor: '#fff', // Ensure background covers scroll
                                                        zIndex: 1, // Keep ID cell above others
                                                      }}
                                                      className='text-center'
                                                      justifyContent='center'
                                                      
                                                    rowSpan={val.length}>
                                                        <Typography variant='subtitle2'>{value.homeName}</Typography>
                                                    </td>
                                                )}
                                                <td>{value.roleName}</td>
                                                <td className='text-center'>{(value.status === "Active") ? <Button color="primary" variant="soft" >Confirmed</Button> : ((value.status === "Hold") ? <Button color="danger" variant="soft" >Not Confirmed</Button> : "N/A")}</td>
                                                <td className='text-center'>
                                                    <Box sx={{ display: 'flex', gap: 1 }}>

                                                        <Button size="sm" variant="soft" color="primary" onClick={()=> handleSubmit(value.roleId, key, value.status)}>
                                                        <ManageSearchIcon /> &nbsp; Search
                                                        </Button>
                                                    </Box>
                                                </td>
                                            </tr>
                                        ))
                                    ))}
                                </tbody>
                            </Table>

                        </Sheet> */}
                        <br></br>
                        {(alert === true) &&
                            <Alert
                                variant="soft"
                                color="danger"
                                invertedColors
                                startDecorator={
                                    // <CircularProgress size="lg" color="danger">
                                    <Warning />
                                    // </CircularProgress>
                                }
                                sx={{ alignItems: 'flex-start', gap: '1rem' }}
                            >
                                <Box sx={{ flex: 1 }}>
                                    <Typography level="title-md">{(status === "Hold") ? "Pending Confirmation - "+homeName : homeName}</Typography>
                                    <Typography level="body-md">
                                        {(status === "Hold") ? "You have not confirmed your consent to share your training records with this home in this role." :
                                            "You have not been assigned a role for this home and this view is for information only."}
                                    </Typography>
                                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                        <Button variant="solid" size="sm" onClick={() => alertSubmit()}>
                                            Proceed
                                        </Button>
                                        <Button variant="outlined" size="sm" onClick={() => {setAlert(false); onClose();}}>
                                            Close
                                        </Button>
                                    </Box>
                                </Box>
                            </Alert>
                        }

                    </Box>
                </DialogContent>
            </Modal.Body >
        </Modal>
    );
}
export default HomeRoleForUser;