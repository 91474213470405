import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal, Container } from 'react-bootstrap'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { Switch } from '@material-ui/core'
import * as apiUtility from "../../config/APIUtils";
import ErrorPopUp from "../Utility/ErrorPopUp";
import { Checkbox } from "@mui/material";
function AddCourseVersion(props) {
    var trainingProvidersMap = JSON.parse(sessionStorage.getItem("training_providers"));
    const [trainingProvider, setTrainingProvider] = useState(0);
    const [completeSelectedCrs, setCompleteSelectedCrs] = useState("");
    const [isPrntCrsDisabled, setIsPrntCrsDisabled] = useState(true);
    const [parentCourseList, setParentCourseList] = useState([]);
    const [selectPrntCrsList, setSelectPrntCrsList] = useState([]);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');
    const [trainingUrl, setTrainingUrl] = useState("");

    var selectedCrs = props.selectedCrs;

    useEffect(() => {
        getAllCourses(props.selectedCrs);
        // $("#equvalentCourseDiv").hide();
        // $("#equivalent_course_id").val("");
        // setIsPrntCrsDisabled(true);
    }, [props.selectedCrs])


    var addNewCourseVersion = async (e) => {
        e.preventDefault();
        setDataForErrorPopup("");
        if (selectPrntCrsList == []) {
            setShowErrorPopup(true);
            setDataForErrorPopup("Parent courses cannot be empty");
            return;
        }
        var temArrSelectedArr = [];
        for (let i = 0; i < selectPrntCrsList.length; i++) {
            temArrSelectedArr.push(selectPrntCrsList[i].split("(")[1].split(")")[0]);
        }
        if (temArrSelectedArr.indexOf(selectedCrs) < 0) {
            setShowErrorPopup(true);
            setDataForErrorPopup("Please select the original course!");
            return;
        }

        var version_number = document.getElementById("version_number").value;
        var is_valid = document.getElementById("is_valid").checked;
        var direct_registration = document.getElementById("direct_registration").checked;
        var crsVerDescription = document.getElementById("crsVerDescription").value;
        //var get_org_name = document.getElementById("get_org_name").value;
        var training_duration_crs_ver = document.getElementById("training_duration_crs_ver").value;
        //document.getElementById("equivalent_course_id").value == "" ? null : document.getElementById("equivalent_course_id").value;
        var eqCrsTitle = document.getElementById("crsVerTitle").value;
        var approvalDuration = document.getElementById("approvalDuration").value;
        if(!approvalDuration){
            approvalDuration = 15;
        }
        
        var version_code = document.getElementById("version_code").value;
        var equivalent_course_id = version_code;
        var trainingDurHrOrMin = document.getElementById("addCrsVerTrainDur").checked == true ? "m" : "h";

        if (selectPrntCrsList.length == 0 || version_code == "" || version_number == "" || crsVerDescription == "" || training_duration_crs_ver == "" || eqCrsTitle == "") {
            setShowErrorPopup(true);
            setDataForErrorPopup("Course version fields cannot be empty");
            return;
        } else if (isNaN(training_duration_crs_ver)) {
            setShowErrorPopup(true);
            setDataForErrorPopup("Training duration can be numeric only!");
            return;
        } else if (trainingProvider == 0 || trainingProvider == "0") {
            setShowErrorPopup(true);
            setDataForErrorPopup("Please select a training provider! Select default if not applicable");
            return;
        } else if (!document.getElementById("isEquvalentCourse").checked && selectPrntCrsList.length > 1) {
            setShowErrorPopup(true);
            setDataForErrorPopup("For non equivalent courses you can choose only one course ");
            return;
        }else if (document.getElementById("isEquvalentCourse").checked && selectPrntCrsList.length <= 1) {
            setShowErrorPopup(true);
            setDataForErrorPopup("For equivalent courses you need to choose one or more courses. ");
            return;
        }  else {
            if (document.getElementById("isEquvalentCourse").checked) {
                equivalent_course_id = version_code;
            }
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "course/create-version";
            apiRequest.data = {
                "course_id": temArrSelectedArr,
                "course_version_id": "",
                "version_number": version_number,
                "is_valid": is_valid,
                "last_modified_date": new Date(),//today
                "training_provider_id": trainingProvider,
                "approval_duration":parseInt(approvalDuration),
                "direct_registration": direct_registration,
                "description": crsVerDescription,
                "training_duration": training_duration_crs_ver + "-" + trainingDurHrOrMin,
                "equivalent_course_id": equivalent_course_id,
                "title": eqCrsTitle,
                "training_url":trainingUrl,
                "tp_course_code":version_code
            };
            apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                var rspMsg = [1, res.data.msg.toString()];
                props.handleCallBack(rspMsg)
            }).catch((error) => {
                console.error(error);
                var rspMsg = [-1, "Error while performing action"];
                props.handleCallBack(rspMsg)
            });
        }
    }

    var getAllCourses = async (propsCourseId) => {
        var allCourses = [];
        var courseStrArr = [];
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "course/view-all-courses";
        //apiRequest.data = userIDListArr;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

            allCourses = res.data;
            for (let i = 0; i < allCourses.length; i++) {
                courseStrArr.push(allCourses[i].title + " (" + allCourses[i].courseID + ")")
                if (propsCourseId == allCourses[i].courseID) {
                    setSelectPrntCrsList([allCourses[i].title + " (" + allCourses[i].courseID + ")"]);
                }
            }
            setParentCourseList(courseStrArr)

        }).catch((error) => {
            console.error(error);
            //setshowSpinner(false);
        });
    }

    const handleTrainingUrl = (event) => {
        const { value } = event.target;
        setTrainingUrl(value);
      };

    const handleChangeForMultiSelectPrtCrs = (event) => {
        const {
            target: { value },
        } = event;

        setSelectPrntCrsList(
            (typeof value === 'string' ? value.split(',') : value)
        );
    }

    return (
        <Container>
            <Row>
                <Modal
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Course version</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <h3 id='emptyFieldMsg' style={{ "textAlign": 'center', "color": "red", "display": "none" }}>Field is Empty</h3>
                            <h3 id='successMesage' style={{ "textAlign": 'center', "color": "green", "display": "none" }}>Your entry was saved</h3>
                        </Row>
                        <Form>
                            <Row>
                                <Col>
                                    {isPrntCrsDisabled == false ?
                                        (<Select
                                            style={{ "width": "100%" }}
                                            labelId="demo-multiple-checkbox-label"
                                            id="multi-select-parentCrs"
                                            multiple
                                            value={selectPrntCrsList}
                                            onChange={handleChangeForMultiSelectPrtCrs}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            defaultValue={[completeSelectedCrs]}

                                        >{parentCourseList.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={selectPrntCrsList.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}</Select>)
                                        :
                                        (<Select
                                            style={{ "width": "100%" }}
                                            labelId="demo-multiple-checkbox-label"
                                            id="multi-select-parentCrs"
                                            multiple
                                            value={selectPrntCrsList}
                                            onChange={handleChangeForMultiSelectPrtCrs}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            defaultValue={[completeSelectedCrs]}
                                            disabled
                                        >
                                            {parentCourseList.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={selectPrntCrsList.indexOf(name) > -1} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>)
                                    }
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="crsVerTitle">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control placeholder="Enter Title" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="crsVerDescription">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control placeholder="Enter Description" />
                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="version_code">
                                    <Form.Label>Course Code</Form.Label>
                                    <Form.Control placeholder="Enter Training provider course Code" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="version_number">
                                    <Form.Label>Version Number</Form.Label>
                                    <Form.Control placeholder="Enter Course Version Number" />
                                </Form.Group>
                            </Row>
                            <br></br>

                            <Row>

                                <Form.Group as={Col} controlId="training_duration_crs_ver">
                                    <Row>
                                        <Col>
                                            <Form.Label>Traning Duration</Form.Label>
                                            <Form.Control placeholder="Training Duration" />
                                        </Col>
                                        <Col>
                                            <Form.Label as={Col}>Traning Duration is in: </Form.Label>

                                            <Form.Label>Hours</Form.Label>
                                            <Switch id="addCrsVerTrainDur" onChange={function () {
                                                console.log(document.getElementById("addCrsVerTrainDur").checked)
                                            }} color="primary" />
                                            <Form.Label>Minutes</Form.Label>
                                        </Col>
                                        {/* <Col>
                                            <Form.Label>Approval Duration</Form.Label>
                                            <Form.Control placeholder="Approval Duration in days" />
                                        </Col> */}
                                    </Row>

                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="is_valid">
                                    <Form.Label defaultChecked>Is valid</Form.Label>
                                    <Form.Check placeholder="Enter Course Version Number" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="direct_registration">
                                    <Form.Label defaultChecked>Direct Registration</Form.Label>
                                    <Form.Check placeholder="Enter Course Version Number" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="isEquvalentCourse" >
                                    <Form.Label defaultChecked>Is An Equvalient Course?</Form.Label>
                                    <Form.Check placeholder="Enter Course Version Number" 
                                    onChange={(e) => {
                                        if (document.getElementById("isEquvalentCourse").checked) {
                                           // $("#equvalentCourseDiv").show();
                                            setIsPrntCrsDisabled(false);
                                       } else {
                                            //$("#equvalentCourseDiv").hide();
                                            //$("#equivalent_course_id").val("");
                                            setIsPrntCrsDisabled(true);
                                       }

                                   }} 
                                    />
                                </Form.Group>
                                {/* <Form.Group as={Col} controlId="equivalent_course_id" id="equvalentCourseDiv">
                                    <Form.Label>Equivalent Course</Form.Label>
                                    <Form.Control placeholder="Leave Empty if not an equivalent course" />
                                </Form.Group> */}

                            </Row>
                            <Row>
                            <Form.Group as={Col} controlId="get_org_name">
                                    <Form.Label>Training Provider</Form.Label>
                                    {/* <Form.Control placeholder="Enter Name of Issuing Organization" /> */}
                                    <Form.Select name="organization" value={trainingProvider} onChange={(e) => setTrainingProvider(e.target.value)} >
                                        <option value="0"> -- Please select Training Provider --  </option>
                                        {trainingProvidersMap?.map((data, id) => (
                                            <option value={data.tp_id} key={data.tp_id}>{data.provider_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            <Form.Group as={Col} controlId="training-URL">
                                        <Form.Label>Training URL</Form.Label>
                                        <Form.Control type="text" value={trainingUrl} onChange={handleTrainingUrl} placeholder="Enter the training URL for this course.." />
                                </Form.Group>
                                <Form.Group as={Col} controlId="approvalDuration">
                                <Form.Label>Approval Duration</Form.Label>
                                <Form.Control placeholder="Approval Duration in days" />
                                </Form.Group>
                            </Row>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" onClick={addNewCourseVersion}>
                            Submit
                        </Button>
                        <Button variant="primary" onClick={props.onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Row>
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />
        </Container>
    )
}

export default AddCourseVersion