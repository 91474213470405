import { Image } from "react-bootstrap";
import pic from "../homepageImg.jpg";
import { BASE_URL_FRONTEND } from "../config/Url-config";
import { Button } from "@mui/joy";

function Homepage() {
  const currentURL = window.location.href;
  if (process.env.NODE_ENV === 'production' && currentURL.includes("herokuapp.com")) {
    alert("Please use new URL : "+BASE_URL_FRONTEND)
    //return window.location.href = BASE_URL_FRONTEND;
  }
  var textStyle = {
    position: 'absolute',
    top: '20%',
    left: '70%',
    color: "#1d3d80"
  };

  const registerPage = () => {
    return window.location.href = BASE_URL_FRONTEND + "userRegistration";
  }
  return <div style={{ width: 'auto' }}>
    <Image style={{ "max-width": "100%", "box-shadow": "0 0 8px 8px white inset", "backgroundSize": 'cover' }} responsive src={pic} />
    <h1 style={textStyle} className="display-5">Simplify your <br></br>Training Record Management <br></br><Button bsStyle="primary" size="md" onClick={registerPage}>SignUp</Button></h1>

  </div>

}






export default Homepage;































