

import style from './organisation.css';
import React, { useState, useEffect, useRef } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner'
import { BASE_API_URL } from '../../config/Url-config';
import { Row, Col } from 'react-bootstrap';
import RoleTemplate from './RoleTemplate';
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { FiRefreshCw } from "react-icons/fi";
import * as apiUtility from "../../config/APIUtils";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material';
import { RiFileExcel2Line } from 'react-icons/ri';

export default function HomeCheckListComponent(props) {

    const ref = useRef(null);

    const [editRoleDetail, setEditRoleDetail] = useState({});
    const [MicroCredShow, setMicroCredShow] = useState(false);
    const handleCloseMicroCred = () => setMicroCredShow(false);
    const handleshowMicroCred = () => setMicroCredShow(true);
    const [roleDetailOverWrite, setroleDetailOverWrite] = useState({});
    const [showTemplateDetails, setShowTemplateDetails] = useState(false)
    const [templateDetails, setTemplateDetails] = useState({});
    const [showArchiveWarning, setArchiveWarningShow] = useState(false);
    const handleCloseArchiveWarning = () => setArchiveWarningShow(false);
    const handleshowArchiveWarning = () => setArchiveWarningShow(true);
    const [showPosition, setPositionShow] = useState(false);
    const handleClosePosition = () => setPositionShow(false);
    const [isSticky, setIsSticky] = useState(false);
    const [tableOffset, setTableOffset] = useState(0);
    const [editRowWidth, setEditTableRowWidth] = useState('');

    const [positionDetail, setPositionDetail] = useState({});
    const handleShowPosition = () => {
        setRoleDetailOptions({});
        setShowDetailForm(false);
        setPositionShow(true);
        setPositionDetail({});
    }
    const [showOption, setShowOption] = useState(false);
    const handleCloseOption = () => setShowOption(false);
    const handleShowOption = () => setShowOption(true);
    const [showOverwriteWarning, setOverwriteWarning] = useState(false);
    const handleCloseOverwriteWarning = () => setOverwriteWarning(false);
    const handleShowOverWriteWarning = () => {
        setOverwriteWarning(true);
    }
    // const params = useParams().id;
    const params = props.id;
    const [roleDetails, setRoleDetails] = useState([]);
    const [radioValue, setRadioButtonValue] = useState([]);
    const [dispMicroCred, setDispMicroCred] = useState('');
    const [globalCourseRole, setGlobalRoleCourse] = useState({});
    const [trainData, setTrainStandardsData] = useState([])
    const [courseList, setCourseList] = useState([]);
    const [showCreateRole, setShowCreateRole] = useState(false);
    const [counter, setCounter] = useState(0);
    const [globalRole, setGlobalRole] = useState({});
    const [loading, setLoading] = useState(false);
    const [showSpinner, setshowSpinner] = useState(false);
    const toggleshowSpinner = () => setshowSpinner(!showSpinner);
    const [checkListRoleId, setCheckListRoleId] = useState('');
    const [checkListHomeId, setCheckListHomeId] = useState('');
    const [editTableWidth, setEditTableWidth] = useState('');
    const [homeArchivedStates, sethomeArchivedStates] = useState([]);
    const [archivedObj, setArchivedObj] = useState({});
    const [checkListRoleDetails, setCheckListRoleDetailsModal] = useState(false);
    const handleCloseCheckListRoleDetails = () => {
        setCheckListRoleDetailsModal(false);
        setShowOverWriteOption(false);
        setroleDetailOverWrite({})
    }
    const [homesType, setHomesType] = useState(JSON.parse(sessionStorage.getItem('home_types')));
    const [selectedHomeType, setSelectedHomeType] = useState(homesType[0]);
    const [selectedArchiveType, setSelectedArchiveType] = useState({ "name": "Active", "value": "2" });

    const [showDetailForm, setShowDetailForm] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [homeList, setHomeList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [roleDetail, setRoleDetail] = useState();
    const [dispRoleDetail, setDispRoleDetail] = useState('');
    const [roleDetailshow, setRoleDetailShow] = useState(false);
    const handleroleDetailClose = () => setRoleDetailShow(false);
    const handleroleDetailShow = () => setRoleDetailShow(true);
    const handleShowDetailForm = () => {
        setShowDetailForm(!showDetailForm);
        setRoleDetailOptions({});
    }
    const [roleDetailOptions, setRoleDetailOptions] = useState({});
    const [showOverWriteOption, setShowOverWriteOption] = useState(false);
    const handleShowOverWriteOption = () => setShowOverWriteOption(!showOverWriteOption);
    const tableRef = useRef();

    const archiveType = [{ 'name': "All", 'value': "1" }, { 'name': "Active", 'value': "2" }, { 'name': "Archived", 'value': "3" }]
    useEffect(() => {
        //console.log(props)
        if (props) {

            getInitialData();

        }

    }, [props.id, selectedHomeType, selectedArchiveType]);





    function handleHomeTypeChange(homeType) {
        console.log(homeType)
        setSelectedHomeType(homeType)
        //  getHomeData()

    }
    function handleArchiveTypeChange(archiveType) {
        setSelectedArchiveType(archiveType);

    }

    function setTableWidthFunc(resLength) {
        if (resLength >= 0) {
            switch (resLength) {
                case 0: setEditTableWidth('68vh');
                    setEditTableRowWidth('100%');
                    break;
                case 1: setEditTableWidth('130vh');
                    setEditTableRowWidth('52.5%');
                    break;
                case 2: setEditTableWidth('170vh');
                    setEditTableRowWidth('40%');
                    break;
                case 3: setEditTableWidth('200vh');
                    setEditTableRowWidth('34%');
                    break;
                case 4: setEditTableWidth('230vh');
                    setEditTableRowWidth('29.6%');
                    break;
                case 5: setEditTableWidth('270vh');
                    setEditTableRowWidth('25.2%');
                    break;
                case 6: setEditTableWidth('310vh');
                    setEditTableRowWidth('22%');
                    break;
                case 7: setEditTableWidth('360vh');
                    setEditTableRowWidth('19%');
                    break;
                case 8: setEditTableWidth('405vh');
                    setEditTableRowWidth('16.8%');
                    break;
                case 9: setEditTableWidth('450vh');
                    setEditTableRowWidth('15.2%');
                    break;
                // 10 
                case 10: setEditTableWidth('500vh');
                    setEditTableRowWidth('13.7%');

                    break;
                case 11: setEditTableWidth('545vh');
                    setEditTableRowWidth('12.5%');

                    break;
                case 12: setEditTableWidth('590vh');
                    setEditTableRowWidth('11.58%');
                    break;
                case 13: setEditTableWidth('640vh');
                    setEditTableRowWidth('10.68%');
                    break;
                case 14: setEditTableWidth('685vh');
                    setEditTableRowWidth('10%');
                    break;
                case 15: setEditTableWidth('735vh');
                    setEditTableRowWidth('9.3%');
                    break;
                case 16: setEditTableWidth('780vh');
                    setEditTableRowWidth('8.7%');
                    break;
                case 17: setEditTableWidth('825vh');
                    setEditTableRowWidth('8.25%');

                    break;
                case 18: setEditTableWidth('870vh');
                    setEditTableRowWidth('7.8%');

                    break;
                case 19: setEditTableWidth('930vh');
                    setEditTableRowWidth('7.34%');

                    break;
                case 20: setEditTableWidth('985vh');
                    setEditTableRowWidth('6.9%');

                    break;
                case 21: setEditTableWidth('1030vh');
                    break;
                case 22: setEditTableWidth('1075vh');
                    break;
                case 23: setEditTableWidth('1400vh');
                    break;
                case 24: setEditTableWidth('1120vh');
                    break;
                case 25: setEditTableWidth('1170vh');
                    break;
                case 26: setEditTableWidth('1220vh');
                    break;
                case 27: setEditTableWidth('1270vh');
                    break;
                case 28: setEditTableWidth('1325vh');
                    break;
                case 29: setEditTableWidth('1370vh');
                    break;
                case 30: setEditTableWidth('1420vh');
                    break;
                case 31: setEditTableWidth('1480vh');
                    break;
                case 32: setEditTableWidth('1560vh');
                    break;
                case 33: setEditTableWidth('1620vh');
                    break;
                case 34: setEditTableWidth('1680vh');
                    break;
                case 35: setEditTableWidth('1740vh');
                    break;
                case 36: setEditTableWidth('1800vh');
                    break;
                case 37: setEditTableWidth('1860vh');
                    break;
                case 38: setEditTableWidth('1920vh');
                    break;
                case 39: setEditTableWidth('1980vh');
                    break;
                case 40: setEditTableWidth('2040vh');
                    break;
                case 41: setEditTableWidth('2300vh');
                    break;
                case 42: setEditTableWidth('2360vh');
                    break;
                case 43: setEditTableWidth('2420vh');
                    break;
                case 44: setEditTableWidth('2480vh');
                    break;
                case 45: setEditTableWidth('2560vh');
                    break;
                case 46: setEditTableWidth('2620vh');
                    break;
                case 47: setEditTableWidth('2680vh');
                    break;
                case 48: setEditTableWidth('2760vh');
                    break;
                case 49: setEditTableWidth('2820vh');
                    break;
                case 50: setEditTableWidth('2880vh');
                    break;
                case 51: setEditTableWidth('2940vh');
                    break;
                case 52: setEditTableWidth('3000vh');
                    break;
                case 53: setEditTableWidth('3070vh');
                    break;
                case 54: setEditTableWidth('3140vh');
                    break;
                case 55: setEditTableWidth('3210vh');
                    break;
                case 56: setEditTableWidth('3280vh');
                    break;
                case 57: setEditTableWidth('3350vh');
                    break;
                case 58: setEditTableWidth('3240vh');
                    break;
                case 59: setEditTableWidth('3310vh');
                    break;
                case 60: setEditTableWidth('3380vh');
                    break;
                default: setEditTableWidth('3450vh');
            }
            // var tableWidth = document.getElementById("tableWidth");
            // tableWidth.style.width = editTableWidth;
        }
    }
    async function getInitialData() {
        var tempTrainingData = [];
        var tempCourselist = [];
        var tempHomeCheckList = [];
        setshowSpinner(true)
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getOrgTrainStandardForHome/" + sessionStorage.getItem("orgId");
        // apiRequest.data = newUser;
        var res = await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res != 'Something went wrong!' || res != 'No Standards Found!') {
                setTrainStandardsData(res.data.tempStandardStatus)
                tempTrainingData = res.data.tempStandard;
            }
        }).catch((error) => {
            console.error(error);
            // setshowSpinner(false);
        });

        setshowSpinner(true)


        const getCoursesUrl = BASE_API_URL + "orgnization/getCourseList/"
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getCourseList/";
        // apiRequest.data = newUser;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res.data != 'Something went wrong!' && res.data != 'No Course Found!') {

                if (selectedHomeType.id == '100' || selectedHomeType.id == '101') {
                    setCourseList(res.data);
                    setshowSpinner(false)

                }
                else {
                    var tempCourseList = [];
                    for (var i = 0; i < res.data.length; i++) {
                        if (res.data[i].type === selectedHomeType.id) {
                            tempCourseList.push(res.data[i]);
                        }
                    }
                    setCourseList(tempCourseList)
                    setshowSpinner(false)

                }
            }
        }).catch((error) => {
            console.error(error);
            setCourseList([]);
        });

        setshowSpinner(true)

        //console.log(params, props.id)

        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/showHomeCheckList/" + params;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

            if (res.data != 'Something went wrong!' && res.data != 'No Role Found!' && res.data.length > 0) {
                var tempRoleArray = [];
                if (selectedArchiveType.value === '1') {
                    tempRoleArray = res.data;
                }
                else if (selectedArchiveType.value === '2') {
                    tempRoleArray = res.data.filter(item => ((item.archived === false) || (item.archived === 'False') || (item.archived === 'false')));
                }
                else {
                    tempRoleArray = res.data.filter(item => ((item.archived === true) || (item.archived === 'True') || (item.archived === 'true')));
                }
                setRoleDetails(tempRoleArray);
                //console.log(tempRoleArray, roleDetails);
                setshowSpinner(false)
                setShowCreateRole(false)
                tempHomeCheckList = tempRoleArray;
                let resLength = tempRoleArray.length
                var arr = {};
                tempRoleArray.forEach(data => {
                    arr[data.role_id] = data.archived;
                });
                sethomeArchivedStates(prevStates => ({ ...prevStates, ...arr }));
                var globalRoleCourseObj = {};
                tempHomeCheckList.forEach(element => {
                    var trainCourseOrgData = tempTrainingData[element.role_id]
                    var tempObj;
                    var courseArray = []
                    if (trainCourseOrgData != undefined) {
                        element.course_details.forEach(data => {
                            tempObj = trainCourseOrgData.some(item => item.id === data.id)

                            if (tempObj == true) {
                                courseArray.push(data.id)
                            }
                        });
                    }
                    globalRoleCourseObj[element.role_id] = courseArray

                });
                setGlobalRoleCourse(globalRoleCourseObj);
                setTableWidthFunc(resLength);
            }
            else {
                setShowCreateRole(true)
                setRoleDetails([])
                setshowSpinner(false)
            }


        }).catch((error) => {
            console.error(error);
            setShowCreateRole(true)
            setshowSpinner(false)
        });

        setshowSpinner(false)
    }

    async function handleCheckOnChange(event, courseDetail, roleDetail, id) {
        var tempArray = roleDetails;
        if (event.target.checked)
            tempArray[id].course_details.push({ "id": courseDetail.courseID, "details": courseDetail.title, "description": courseDetail.description })
        else
            tempArray[id].course_details = tempArray[id].course_details.filter(function (el) { return el.id != courseDetail.courseID; });
        setRoleDetails(tempArray)

        console.log(roleDetails)
        const editCourseDetails = BASE_API_URL + "orgnization/editCourseDetails/"

        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/editCourseDetails/";
        apiRequest.data = roleDetail;
        apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            getInitialData()
        }).catch((error) => {
            console.error(error);
            setshowSpinner(false);
        });


    }

    function getMicrodetails(event, val) {
        if (val.description) {
            var obj = {}
            obj['description'] = val.description
            obj['title'] = val.title
            obj['training_duration'] = val.training_duration
            obj['validity_duration'] = val.validity_duration.years + " years " + val.validity_duration.months + " months"
            setDispMicroCred(obj);

        }
        else
            setDispMicroCred(val.title)
        handleshowMicroCred()

    }
    function changeHomeArchiveStatus(event, role_id) {
        const newhomeArchivedStates = { ...homeArchivedStates };
        newhomeArchivedStates[role_id] = event.target.value;
        var tempArchiveObj = {};
        tempArchiveObj.role_id = String(role_id);
        tempArchiveObj.archived = (event.target.value);
        if (event.target.value === "True" || event.target.value === "true" || event.target.value === true) {
            tempArchiveObj.archived = true;
        }
        else {
            tempArchiveObj.archived = false;
        }
        tempArchiveObj.home_id = String(params);
        tempArchiveObj.archivedStates = newhomeArchivedStates
        setArchivedObj(tempArchiveObj);
        handleshowArchiveWarning();

    }

    async function saveArchiveStatus() {
        handleCloseArchiveWarning();
        sethomeArchivedStates(archivedObj.archivedStates);
        const archiveStatusUrl = BASE_API_URL + "orgnization/editRoleArchiveStatus";
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/editRoleArchiveStatus";
        apiRequest.data = { "role_id": archivedObj.role_id, "archived": archivedObj.archived, "home_id": archivedObj.home_id };
        apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            alert("Archive Status Changed!")
        }).catch((error) => {
            console.error(error);
            alert("Something Went Wrong!")
        });

    }
    async function getNewRole(event, data) {

        handleCloseOption()
        //console.log(globalRole)
        const addNewRoleUrl = BASE_API_URL + "orgnization/addCheckListRole/"
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/addCheckListRole/";
        apiRequest.data = globalRole;
        var res = await apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            getInitialData()
        }).catch((error) => {
            console.error(error);
            //setshowSpinner(false);
        });


    }
    function addRoleText(event, id) {
        var tempObj = globalRole;
        tempObj.role_name = event.target.value;
        tempObj.role_id = String(roleDetails.length + 1);
        delete tempObj._id;

        setGlobalRole(tempObj)

    }
    function addPositionText(event, id) {
        let newPositionDetailObj = {};
        newPositionDetailObj = { ...positionDetail };
        if (id === 'pos1')
            newPositionDetailObj.role_name = event.target.value;
        else
            newPositionDetailObj.role_details = event.target.value;

        newPositionDetailObj.role_id = String(roleDetails.length + 1);

        newPositionDetailObj.home_id = String(params);
        newPositionDetailObj.archived = false;
        newPositionDetailObj.org_id = sessionStorage.getItem("orgId");
        setPositionDetail(newPositionDetailObj);
        //console.log("position details:", positionDetail)


    }
    async function savePositionDetail() {
        let newPositionDetailObj = {};
        newPositionDetailObj = { ...positionDetail };
        console.log(positionDetail);
        console.log(roleDetail);
        if ((newPositionDetailObj.role_name && newPositionDetailObj.role_details)) {
            // newPositionDetailObj.course_details = JSON.parse(sessionStorage.getItem('roleSelectOption'));
            setPositionDetail(newPositionDetailObj)
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/addNewPosition";
            apiRequest.data = newPositionDetailObj;
            console.log(positionDetail, roleDetail)
            apiUtility.sendAsyncAPIDataPOST(apiRequest).then(res => {
                handleClosePosition()
                getInitialData()
            }).catch((error) => {
                console.error(error);
            });
        }

    }

    function handleShowCheckListRoleDetails(e, homeId, roleId) {
        getOrgDetails()

        const params = homeId + "/" + roleId

        const getRoleTemplateUrl = BASE_API_URL + "orgnization/getRoleTemplateDetails/" + homeId + "/" + roleId
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getRoleTemplateDetails/" + homeId + "/" + roleId;
        //apiRequest.data = tempHomeObj;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res == 'Something went wrong!' || res == 'No Role Found!')
                console.log("No data")
            else {
                console.log(res.data[0]);
                setTemplateDetails(res.data[0])
                if (templateDetails != {})
                    setShowTemplateDetails(true)
            }
        }).catch((error) => {
            setShowTemplateDetails(false)

            console.error(error);
            // setshowSpinner(false);
        });
        setShowTemplateDetails(true)

        setCheckListHomeId(homeId)
        setCheckListRoleId(roleId)
        setCheckListRoleDetailsModal(true);

    }



    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);

        // The application/octet-stream MIME type is used for unknown binary files.
        // It preserves the file contents, but requires the receiver to determine file type,
        // for example, from the filename extension.
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }
    const exportInExcel = async () => {
        var rowsCount = 6;
        //const title = [{A: 'Ontario Long Term Care Orientation Co-operative Webportal - Error History Report'}];
        const title = [{ '1': 'Ontario Long Term Care Orientation Co-operative Webportal - Home Role Template' }];

        var reportView = [{ '1': 'Name', '2': 'User ID', '3': 'Role', '4': 'Date' }];
        reportView.push({
            '1': sessionStorage.getItem('fullName'),
            '2': sessionStorage.getItem('userId'),
            '3': (sessionStorage.getItem('userType') !== null)?sessionStorage.getItem('userType').toUpperCase():"User",
            '4': (new Date().toLocaleString()).split(',')[0]
        });
        reportView.push("");
        reportView.push("");

        // let reportHeaders = {A:'C-Number',B:'First Name',C:'Last Name',D:'Course Code',E:'Course Name',F:'Completion Date',G:'Last Processed',H:'Failure Reason'};
        // finalExcelArray.push(reportHeaders);
        var finalExcelArray = []

        for (var j = 0; j < courseList.length; j++) {
            var tempExcelArray = [];
            let course = courseList[j].title + '(' + courseList[j].courseID + ')';
            tempExcelArray.push(course);
            for (var i = 0; i < roleDetails.length; i++) {

                // tempExcelObj.course = course;
                if (roleDetails[i].course_details.find(item => item.id == courseList[j].courseID) != undefined) {
                    tempExcelArray.push('Y')

                }
                else {
                    tempExcelArray.push('N')
                }

            }
            finalExcelArray.push(tempExcelArray)
        }
        // const title = [{ '1': 'Home Checklist', '2': new Date().toLocaleString() }];
        let userTable = [];
        var tObj = [];
        var index = '1';
        tObj[index] = 'Courses';
        for (var i = 0; i < roleDetails.length; i++) {
            index = String(parseInt(index) + 1);

            tObj[index] = roleDetails[i].role_name + '(Archived:' + roleDetails[i].archived + ')'

        }
        userTable.push(tObj)
        //  console.log("finalExcelArray ",finalExcelArray)
        for (var i = 0; i < finalExcelArray.length; i++) {
            var index = '1';
            let tempObj = {};
            for (var j = 0; j < finalExcelArray[i].length; j++) {
                tempObj[index] = finalExcelArray[i][j]

                // console.log("index ",index)
                index = String(parseInt(index) + 1);
            }
            userTable.push(tempObj);
            //     userTable.push({
            //         [index] : finalExcelArray[i][j]

            // });  
        }

        console.log('User data : ', userTable[0]);
        const finalData = [...title, ...reportView, ...userTable];
        const worksheet = XLSX.utils.json_to_sheet(finalData, {
            skipHeader: true,
        });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, +"_Report");
        const workbookblob = workbook2blob(workbook);
        // var headerIndexes = [];
        // finalData.forEach((data, index) =>
        //     data["A"] === "Course Code" ? headerIndexes.push(index) : null
        // );
        const dataInfo = {
            titleCell: "A2",
            titleRange: "A1:L1",
            tbodyRange: `A7:H${finalData.length}`,
            uHeadRange: `A2:G2`,
            uBodyRange: `A3:G3`,

        };


        XlsxPopulate.fromDataAsync(workbookblob).then((workbook) => {
            workbook.sheets().forEach((sheet) => {
                for (var i = 1; i <= roleDetails.length + 1; i++) {
                    sheet.column(i).width(40);
                }


                sheet.range(dataInfo.titleRange).merged(true).style({
                    // bold: true,
                    // fontFamily:"Arial",
                    horizontalAlignment: "left",
                    // verticalAlignment: "left",
                });
                sheet.range(dataInfo.uHeadRange).style({
                    // fill: "A6A6A6",
                    //bold: true,
                    horizontalAlignment: "left",
                });
                sheet.range(dataInfo.uBodyRange).style({
                    // bold: true,
                    horizontalAlignment: "left",
                });
                if (dataInfo.tbodyRange) {
                    sheet.range(dataInfo.tbodyRange).style({
                        horizontalAlignment: "left",
                    });
                }

            });

            return workbook.outputAsync().then((workbookBlob) => {

                console.log("Ended with download");
                const downloadAnchorNode = document.createElement("a");
                downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                downloadAnchorNode.setAttribute("download", "ChecklistSheet-" + ".xlsx");
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
                //alert('File downloaded successfully.');
                // setauditalertshow(true);


            });
        });


        //return XLSX.writeFile(blob, "UserAuditReport_"+userList.user_id+"_LCPT.xlsx");//URL.createObjectURL(workbookBlob));
    }
    function openDispRoleDetail(event, data) {
        ////console.log(event.target.value)
        setDispRoleDetail(data)
        handleroleDetailShow()

    }

    function getOrgDetails() {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getOrganizationList";
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            ////console.log(res)
            setOrgList(res.data);
        }).catch((error) => console.error(error));
    }

    function selectOrgForRole(event) {
        if (event.target.value) {
            var tempRoleDetails = {};
            tempRoleDetails.org_id = event.target.value;
            tempRoleDetails.home_id = '';
            tempRoleDetails.role_id = '';
            // setHomeList([]);
            // setRoleList([]);
            setRoleDetailOptions(tempRoleDetails)
            console.log("org", roleDetailOptions);
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/getHomesList/" + event.target.value;
            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                //console.log(res)
                if (res.length == 0 || !res.data || !res || res.data === 'No Home Found!') {
                    setHomeList([])
                }
                else {
                    setHomeList(res.data);
                    var apiRequest = apiUtility.apiInputRequest;
                    apiRequest.endPoint = "orgnization/getOrganisationDetails/" + event.target.value;
                    apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

                        if (res != 'Something went wrong!' && res != 'No Standards Found!' && res.data) {
                            setRoleList(res.data[0].train_standards)

                            console.log(roleList)

                        }
                        else {
                            setRoleList([]);

                        }

                    }).catch((error) => console.error(error))
                }
            }).catch((error) => console.error(error))

        }
    }

    function selectHomeForRole(event) {
        if (event.target.value) {
            //console.log("home", event.target.value);
            var tempRoleDetails = { ...roleDetailOptions };
            tempRoleDetails.home_id = event.target.value;
            tempRoleDetails.role_id = '';

            setRoleDetailOptions(tempRoleDetails)

            console.log("home", event.target.value);
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/showHomeCheckList/" + event.target.value;

            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                
                if (res.data === 'No Role Found!') {
                    setRoleList([]);

                }
                else {
                    setRoleList(res.data);
                    console.log(res.data,roleList)
                }
            }).catch((error) => console.error(error))
        }
    }

    function selectRoleForCopy(event) {
        console.log(roleDetailOptions)
        console.log("Role--------->", event.target.value);
        if (event.target.value) {
            var tempRoleDetails = { ...roleDetailOptions };
            tempRoleDetails.role_id = event.target.value;
            var copyRoleDetails = roleList.find(item => item.role_id === event.target.value)
            tempRoleDetails.course_details = copyRoleDetails.course_details
            var newPositionDetailObj = { ...positionDetail };
            newPositionDetailObj.course_details = copyRoleDetails.course_details;
            setPositionDetail(newPositionDetailObj)
            setRoleDetailOptions(tempRoleDetails)

            console.log(copyRoleDetails)
            var roleObj = {};
            roleObj.role_id = copyRoleDetails.role_id;
            roleObj.role_details = copyRoleDetails.role_details;
            roleObj.role_name = copyRoleDetails.role_name;
            roleObj.course_details = copyRoleDetails.course_details;
            roleObj.archived = copyRoleDetails.archived;
            //sessionStorage.setItem('roleSelectOption', JSON.stringify(copyRoleDetails.course_details));

            console.log("role", roleObj, roleDetailOptions);
            setRoleDetail(roleObj)
        }


    }

    function copyRole() {
        console.log("roleDetails:", roleDetail);
        sessionStorage.setItem('copyRoleObj', JSON.stringify(roleDetail));
    }
    function clearAllSetting() {
        setRoleList([]);
        setOrgList([]);
        setHomeList([]);
    }

    function selectOrgForEdit(event) {
        if (event.target.value) {
            var tempRoleDetails = {};
            tempRoleDetails.org_id = event.target.value;
            tempRoleDetails.home_id = '';
            tempRoleDetails.role_id = '';
            setEditRoleDetail(tempRoleDetails)
            console.log("org", editRoleDetail);
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/getHomesList/" + event.target.value;
            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                //console.log(res)
                if (res.length == 0 || !res.data || !res || res.data === 'No Home Found!') {
                    setHomeList([])
                }
                else {
                    setHomeList(res.data);
                    var apiRequest = apiUtility.apiInputRequest;
                    apiRequest.endPoint = "orgnization/getOrganisationDetails/" + event.target.value;
                    apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

                        if (res != 'Something went wrong!' && res != 'No Standards Found!' && res.data) {
                            setRoleList(res.data[0].train_standards)

                            console.log(roleList)

                        }
                        else {
                            setRoleList([]);

                        }

                    }).catch((error) => console.error(error))
                }
            }).catch((error) => console.error(error))


        }
    }
    function selectHomeForEdit(event) {
        //setRoleList([]);
        if (event.target.value) {
            console.log("home", event.target.value);
            var tempRoleDetails = { ...editRoleDetail };
            tempRoleDetails.home_id = event.target.value;
            tempRoleDetails.role_id = '';

            setEditRoleDetail(tempRoleDetails)

            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/showHomeCheckList/" + event.target.value;

            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                setRoleDetailOptions(tempRoleDetails);
                if (res.data === 'No Role Found!') {
                    setRoleList([]);

                }
                else {
                    setRoleList(res.data);
                }

            }).catch((error) => console.error(error))
        }

    }
    function selectRoleForEdit(event) {
        console.log(editRoleDetail)
        if (event.target.value) {
            var tempRoleDetails = { ...editRoleDetail };
            tempRoleDetails.role_id = event.target.value;
            setEditRoleDetail(tempRoleDetails);
            console.log(editRoleDetail, tempRoleDetails);

            var copyRoleDetails = roleList.find(item => item.role_id === event.target.value);
            setroleDetailOverWrite(copyRoleDetails);

        }


    }
    function handleOverWriteRole(role_id) {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/editCourseDetails/";
        apiRequest.data = { "home_id": params, "role_id": role_id, "course_details": roleDetailOverWrite.course_details };
        console.log(roleDetail)
        apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            //console.log(res)
            setshowSpinner(false);
            handleCloseOverwriteWarning();
            handleCloseCheckListRoleDetails();
            getInitialData()
        }).catch((error) => {
            console.error(error);
            setshowSpinner(false);
        });



    }

    // const renderTooltip1 = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Refresh Template
    //     </Tooltip>
    // );
    const scroll = (scrollOffset, direction) => {
        if (direction === 'horizontal') {
            ref.current.scrollLeft += scrollOffset;
        } else if (direction === 'vertical') {
            ref.current.scrollTop += scrollOffset;
        }
    };

    return (

        <div className="w-100" style={{ marginTop: "5vh" }} id='tableWidth'>
            <Modal show={checkListRoleDetails} onHide={handleCloseCheckListRoleDetails} className=" custom-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title> Role Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RoleTemplate roleId={checkListRoleId} homeId={checkListHomeId} />
                    <Button variant="primary" onClick={handleShowOverWriteOption}>overwrite from existing</Button>
                    {showOverWriteOption ?
                        <div>
                            <Row >
                                <Col>
                                    <Form.Label>Organisation</Form.Label>
                                    <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectOrgForEdit}>
                                        <option value=''>Select an Organization</option>
                                        {orgList?.map((data) => {
                                            return <option value={data.org_id}>{data.org_name}</option>;
                                        })}
                                    </Form.Select>
                                </Col>

                                <Col>
                                    <Form.Label>Home</Form.Label>
                                    <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectHomeForEdit}>
                                        <option value=''>Select a Home</option>

                                        {homeList?.map((data) => {
                                            if (data.homeActiveStatus === true) {
                                                return <option value={data.home_id}>{data.name}</option>;
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Form.Label>Role</Form.Label>
                                    <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectRoleForEdit}>
                                        <option value=''>Select a Role</option>
                                        {roleList?.map((data) => {
                                            if (data.archived === "False") {
                                                return <option value={data.role_id}>{data.role_name}</option>;
                                            } else {
                                                return null;
                                            }
                                            // return <option value={data.role_id}>{data.role_name}</option>;
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col></Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                        <Form.Label style={{ display: "block" }}><b>Courses to overwrite this Role : </b></Form.Label>
                                        {roleDetailOverWrite && roleDetailOverWrite.course_details ? (
                                            roleDetailOverWrite.course_details.map((data, id) => {
                                                return <ul key={id}>
                                                    <li><b>{data.id}</b> - {data.details}</li>
                                                </ul>
                                            })
                                        ) : (

                                            <p>-</p>)
                                        }
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                        <Form.Label style={{ display: "block" }}><b>Present Courses for this Role : </b></Form.Label>
                                        {showTemplateDetails && templateDetails.course_details ? (
                                            templateDetails.course_details.map((data, id) => {
                                                return <ul key={id}>
                                                    <li><b>{data.id}</b> - {data.details}</li>
                                                </ul>
                                            })
                                        ) : (

                                            <p>-</p>)
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="warning" onClick={handleShowOverWriteWarning}  >Save OverWrite Changes</Button>
                        </div> :
                        <div></div>}
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseCheckListRoleDetails} >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal style={{ marginLeft: "25%", marginTop: "10%", width: "50%" }} show={showOverwriteWarning} onHide={handleCloseOverwriteWarning} className=" custom-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title> Confirm Overwrite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You will be overwriting the training template with the new selection - please confirm this action.

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleOverWriteRole(checkListRoleId)}>
                        Confirm
                    </Button>
                    <Button variant="primary" onClick={handleCloseOverwriteWarning}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>



            <Row>
                {/* <Col className='text-left text-muted'><h1>Home Position Template Details</h1></Col>
                <Col className='text-right'><Col>
                    <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip1}
                    >
                        <Button variant="warning" className="btn-md" onClick={() => getInitialData()} style={{ margin: "2%", float: "right", fontWeight: "1pc" }}><FiRefreshCw /></Button>
                    </OverlayTrigger>
                </Col><Col><Button variant='warning' onClick={handleShowPosition} className="btn-md" style={{ margin: "2%", float: "right" }} >Create New Template</Button></Col></Col> */}
                {/* <Col xs={12} md={6} className='text-left text-muted'>
                    <Typography variant="h5" component="h2">Home Position Template Details</Typography>
                </Col> */}
                {/* <Col className='text-right'>
                        
                        <Col>
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip1}
                        >
                            <Button variant="warning" className="btn-md" onClick={() => getInitialData()} style={{ margin: "2%", float: "right", fontWeight: "1pc" }}><FiRefreshCw /></Button>
                        </OverlayTrigger>
                    </Col>
                    <Button variant='warning' onClick={(e) => {
                                { exportInExcel() }
                            }}>Export to Excel</Button>
                        <Col>
                            <Button variant='warning' onClick={handleShowPosition} className="btn-md" style={{ margin: "2%", float: "right" }} >Create New Template</Button>
                        </Col>
                    </Col> */}

                <Col xs={12} md={12}>
                    <Paper component="form" variant="outlined"
                        sx={{ float: "right", display: 'flex', marginBottom: "4px", alignItems: 'center', width: 150 }}>
                        <Tooltip variant="contained" title="Create New Template" disableRipple>
                            <IconButton color="primary" onClick={() => handleShowPosition()} sx={{ p: '10px' }} aria-label="directions">
                                <CreateNewFolderIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                        <Tooltip variant="contained" title="Refresh Data" disableRipple>
                            <IconButton color="primary" onClick={() => getInitialData()} sx={{ p: '10px' }} aria-label="directions">
                                <FiRefreshCw />
                            </IconButton>
                        </Tooltip>
                        <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                        <Tooltip variant="contained" title="Export to Excel" disableRipple>
                            <IconButton color="primary" onClick={() => exportInExcel()} sx={{ p: '10px' }} aria-label="directions">
                                <RiFileExcel2Line />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                </Col>
            </Row>
            <Row >

                {/* <div className="w-100" style={{ overflowX: "scroll", position: "relative" }}>


                    {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                        <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

                    </div> : <div></div>}
                    <div>
                        <Modal show={showPosition} onHide={handleClosePosition} style={{ width: "70%", left: "13%" }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Position</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                                        <Form.Label>Enter the Position Name</Form.Label>
                                        <Form.Control type="text"
                                            onChange={(e) => {
                                                addPositionText(e, 'pos1');
                                            }}
                                        />
                                        <Form.Label>Enter the Position Details</Form.Label>
                                        <Form.Control type="text"
                                            onChange={(e) => {
                                                addPositionText(e, 'pos2');
                                            }}
                                        />
                                        {!showDetailForm ? <Button style={{ margin: "1%" }} onClick={() => { handleShowDetailForm(); getOrgDetails() }}>Copy from existing</Button> :
                                            <div>                            <Button style={{ margin: "1%" }} onClick={() => { handleShowDetailForm(); clearAllSetting() }}>Hide Details</Button>
                                                <Row>
                                                    <h4>Copy Role and its courses</h4>
                                                    <Col>
                                                        <Form.Label>Organisation</Form.Label>
                                                        <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectOrgForRole}>
                                                            <option value=''>Select an Organization</option>
                                                            {orgList?.map((data) => {
                                                                return <option value={data.org_id}>{data.org_name}</option>;
                                                            })}
                                                        </Form.Select>
                                                    </Col>

                                                    <Col>
                                                        <Form.Label>Home</Form.Label>
                                                        <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectHomeForRole}>
                                                            <option value=''>Select a Home</option>

                                                            {homeList?.map((data) => {
                                                                if (data.homeActiveStatus === true) {
                                                                    return <option value={data.home_id}>{data.name}</option>;
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </Form.Select>
                                                    </Col>
     
    

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Role</Form.Label>
                                                        <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectRoleForCopy}>
                                                            <option value=''>Select a Role</option>
                                                            {roleList?.map((data) => {
                                                                if (data.archived === "False") {
                                                                    return <option value={data.role_id}>{data.role_name}</option>;
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </Form.Select>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>


                                            </div>


                                        }

                                    </Form.Group>



                                    {showDetailForm ? <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                        <Row>
                                            <Col>
                                                <Form.Label style={{ display: "block" }}><b>Courses Mandatory for this Role : </b></Form.Label>
                                                {roleDetailOptions && roleDetailOptions.course_details ? (
                                                    roleDetailOptions.course_details.map((data, id) => {
                                                        return <ul key={id}>
                                                            <li><b>{data.id}</b> - {data.details}</li>
                                                        </ul>
                                                    })
                                                ) : (

                                                    <p>-</p>)
                                                }
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                                    <Form.Label style={{ display: "block" }}><b>Present Courses for this Role : </b></Form.Label>
                                                    -
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                    </Form.Group> : <div></div>}



                                </Form>

                            </Modal.Body>
                            <Modal.Footer>
                               
                                <Button variant="secondary" onClick={handleClosePosition}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={savePositionDetail}  >
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                   
                    <Table striped bordered hover style={{ width: `${editTableWidth}`, tableLayout: "fixed" }} id="table-id" className='table-responsive'>
                        <thead >
                        <th style={{ width: "10vh", textAlign: "center", left: "0px", backgroundColor: "rgb(255 255 255)", zIndex: "99", position: "sticky" }}>

                                <Row style={{ paddingBottom:"8vh"}}>
                                    <Col><Form.Label style={{ textAlign: "left", "float": "right" }} className='text-muted'>Filter Courses</Form.Label></Col>
                                    <Col>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="warning" id="dropdown-basic">
                                                {selectedHomeType.value}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu >
                                                {homesType?.map((item) => {
                                                    return <Dropdown.Item onClick={() => handleHomeTypeChange(item)}>{item.value}</Dropdown.Item>
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>

                                    <Col><Form.Label style={{ textAlign: "left", "float": "right" }} className='text-muted'>Filter by Role Status</Form.Label></Col>
                                    <Col>
                                        <Dropdown style={{ "float": "left" }}>
                                            <Dropdown.Toggle variant="warning" id="dropdown-basic">
                                                {selectedArchiveType.name}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {archiveType?.map((item) => {
                                                    return <Dropdown.Item onClick={() => handleArchiveTypeChange(item)}>{item.name}</Dropdown.Item>
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <h5 style={{ top: "10%" }}> Course Title</h5>
                            </th>
                            {roleDetails?.map((data, id) => {
                                return <th key={id} style={{ width: "5vh" ,position:"sticky"}}>
                                    <p>
                                        <Button variant="warning" onClick={(e) => {
                                            createRole(e, data);
                                        }} style={{  backgroundColor: "#ffc107" }} className="btn-sm">Duplicate Template</Button>
                                    </p>
                                    <Modal show={showOption} onHide={handleCloseOption}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Enter Role Name</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form>
                                                <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                                                    <Form.Label>Enter the Role Name</Form.Label>
                                                    <Form.Control type="text"
                                                        onChange={(e) => {
                                                            addRoleText(e, 'hm1');
                                                        }}
                                                    />

                                                </Form.Group>

                                            </Form>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseOption} >
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={getNewRole} >
                                                Save Changes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <p><Form>
                                        <Form.Group className="sm-2 col-xs-6" controlId="formBasicEmail">
                                            <Form.Label className='text-muted'>Role Archive Status</Form.Label>
                                            <br></br>
                                            {trainData[data.role_id] != undefined && trainData[data.role_id] == "False" ?
                                                <ButtonGroup>
                                                    <ToggleButton
                                                        id={`radio11-${id}`}
                                                        type="radio"
                                                        variant={homeArchivedStates[data.role_id] == 'True' ? 'danger' : 'outline-danger'}
                                                        name={`radio1-${id}`}
                                                        value='True'
                                                        size="sm"
                                                        disabled

                                                        checked={homeArchivedStates[homeArchivedStates[data.role_id]]}
                                                        onChange={(e) => { changeHomeArchiveStatus(e, data.role_id) }}
                                                    >
                                                        Archived
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        id={`radio21-${id}`}
                                                        type="radio"
                                                        variant={homeArchivedStates[data.role_id] == 'True' ? 'outline-success' : 'success'}
                                                        name={`radio2-${id}`}
                                                        value='False'
                                                        size="sm"
                                                        disabled
                                                        checked={homeArchivedStates[homeArchivedStates[data.role_id]]}
                                                        onChange={(e) => { changeHomeArchiveStatus(e, data.role_id) }}


                                                    >
                                                        Active
                                                    </ToggleButton>
                                                </ButtonGroup>
                                                :
                                                <ButtonGroup>
                                                    <ToggleButton
                                                        id={`radio11-${id}`}
                                                        type="radio"
                                                        variant={homeArchivedStates[data.role_id] == 'True' ? 'danger' : 'outline-danger'}
                                                        name={`radio1-${id}`}
                                                        value='True'
                                                        size="sm"

                                                        checked={homeArchivedStates[homeArchivedStates[data.role_id]]}
                                                        onChange={(e) => { changeHomeArchiveStatus(e, data.role_id) }}
                                                    >
                                                        Archived
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        id={`radio21-${id}`}
                                                        type="radio"
                                                        variant={homeArchivedStates[data.role_id] == 'True' ? 'outline-success' : 'success'}
                                                        name={`radio2-${id}`}
                                                        value='False'
                                                        size="sm"
                                                        checked={homeArchivedStates[homeArchivedStates[data.role_id]]}
                                                        onChange={(e) => { changeHomeArchiveStatus(e, data.role_id) }}


                                                    >
                                                        Active
                                                    </ToggleButton>
                                                </ButtonGroup>}

                                        </Form.Group>
                                    </Form></p>
                                    <Modal style={{ width: "50%", left: "25%", top: "20%" }} show={showArchiveWarning} onHide={handleCloseArchiveWarning}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Warning</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <h5>Are you sure you want to change the Archive Status for this role?</h5>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseArchiveWarning}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" onClick={saveArchiveStatus}>
                                                Yes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>                                    <p style={{ textAlign: "center", cursor: "pointer" }}><b onClick={(e) => {handleShowCheckListRoleDetails(e, data.home_id, data.role_id);}}>{data.role_name}</b></p>

                                    <p>
                                        
                                        <Modal show={roleDetailshow} onHide={handleroleDetailClose} style={{ width: "50%", left: "20%" }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Role Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {dispRoleDetail ?
                                                    <div>{dispRoleDetail.role_details}</div> : <div></div>

                                                }


                                            </Modal.Body>
                                            <Modal.Footer>
                                                {
                                                    (!sessionStorage.getItem('copyRoleObj')) ? <div></div> :
                                                        <Button variant="warning" onClick={handleOverWriteRole}>OverWrite Role Template</Button>
                                                }
            
                                                <Button variant="secondary" onClick={handleroleDetailClose} >
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal></p>

                                </th>
                            })
                            }


                        </thead>
                        </Table>
                        <div style={{overflowY:"scroll",maxHeight:"400px",width:`${editTableWidth}`,overflowX:"auto" }}>
                 <Table style={{tableLayout: "fixed", width: "100%"}} ref={tableRef}>
                            
                            <tbody>
                            {courseList?.map((courses, id) => {
    
                                return <tr key={id} >
                    <td style={{ position: "sticky", left: "0px", backgroundColor: "rgb(250 246 246)", zIndex: "999",width: "10vh" }}><b style={{ cursor: "pointer"  }} onClick={(e) => {
                                        getMicrodetails(e, courses);
                                    }}>{courses.title}</b><div>
                                            
                                            <Modal show={MicroCredShow} onHide={handleCloseMicroCred}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>View MicroCred Details</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <h5><b>Title</b> : {dispMicroCred.title}</h5>
                                                    <h5><b>Description</b> : {dispMicroCred.description}</h5>
                                                    <h5><b>Validity Duration</b> : {dispMicroCred.validity_duration}</h5>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                </Modal.Footer>
                                            </Modal>
                                        </div></td>
                                    {roleDetails?.map((data, _id) =>
                                        (globalCourseRole[data.role_id]?.includes(courses.courseID)) ?
                                            <td key={_id} style={{ borderRight: "1px solid #000", borderLeft: "1px solid #000", width: "5vh" }}>
    
    
                                                {trainData[data.role_id] != undefined && trainData[data.role_id] == "False" ? <input
                                                    type="checkbox" className='yes' disabled style={{ transform: "scale(2)", marginLeft: "50%" }}
                                                    defaultChecked={(roleDetails[_id].course_details.find(item =>
                                                        item.id == courseList[id].courseID
                                                    ) != undefined)} onChange={(e) => {
                                                        handleCheckOnChange(e, courses, data, _id);
                                                    }}
                                                /> : <input
                                                    type="checkbox" className='yes' style={{ transform: "scale(2)", marginLeft: "50%" }}
                                                    defaultChecked={(roleDetails[_id].course_details.find(item =>
                                                        item.id == courseList[id].courseID
                                                    ) != undefined)} onChange={(e) => {
                                                        handleCheckOnChange(e, courses, data, _id);
                                                    }}
                                                />}
                                            </td>
                                            :
                                            <td key={_id} style={{ borderRight: "1px solid #000", borderLeft: "1px solid #000", width: "5vh" }}>
                                                <input
                                                    type="checkbox" className='no' style={{ transform: "scale(2)", marginLeft: "50%" }}
                                                    defaultChecked={(roleDetails[_id].course_details.find(item =>
                                                        item.id == courseList[id].courseID
                                                    ) != undefined)} onChange={(e) => {
                                                        handleCheckOnChange(e, courses, data, _id);
                                                    }} />
                                            </td>
    
    
    
                                    )}
    
                                </tr>
                            })}
                           
                            </tbody>
                           
                        </Table>    
                         
                        <Button variant='warning' onClick={(e) => {
                            { exportInExcel() }
                        }}>Export to Excel</Button>
                        </div>
                </div> */}




















                <div className="w-100">


                    {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                        <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

                    </div> : " "}
                    <div>
                        <Modal show={showPosition} onHide={handleClosePosition} style={{ width: "70%", left: "13%" }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Position</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                                        <Form.Label>Enter the Position Name</Form.Label>
                                        <Form.Control type="text"
                                            onChange={(e) => {
                                                addPositionText(e, 'pos1');
                                            }}
                                        />
                                        <Form.Label>Enter the Position Details</Form.Label>
                                        <Form.Control type="text"
                                            onChange={(e) => {
                                                addPositionText(e, 'pos2');
                                            }}
                                        />
                                        {!showDetailForm ? <Button style={{ margin: "1%" }} onClick={() => { handleShowDetailForm(); getOrgDetails() }}>Copy from existing</Button> :
                                            <div>                            <Button style={{ margin: "1%" }} onClick={() => { handleShowDetailForm(); clearAllSetting() }}>Hide Details</Button>
                                                <Row>
                                                    <h4>Copy Role and its courses</h4>
                                                    <Col>
                                                        <Form.Label>Organisation</Form.Label>
                                                        <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectOrgForRole}>
                                                            <option value=''>Select an Organization</option>
                                                            {orgList?.map((data) => {
                                                                return <option value={data.org_id}>{data.org_name}</option>;
                                                            })}
                                                        </Form.Select>
                                                    </Col>

                                                    <Col>
                                                        <Form.Label>Home</Form.Label>
                                                        <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectHomeForRole}>
                                                            <option value=''>Select a Home</option>

                                                            {homeList?.map((data) => {
                                                                if (data.homeActiveStatus === true) {
                                                                    return <option value={data.home_id}>{data.name}</option>;
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </Form.Select>
                                                    </Col>



                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Role</Form.Label>
                                                        <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectRoleForCopy}>
                                                            <option value=''>Select a Role</option>
                                                            {roleList?.map((data) => {
                                                                if (data.archived === "False" || data.archived === false) {
                                                                    return <option value={data.role_id}>{data.role_name}</option>;
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </Form.Select>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>


                                            </div>


                                        }

                                    </Form.Group>



                                    {showDetailForm ? <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                        <Row>
                                            <Col>
                                                <Form.Label style={{ display: "block" }}><b>Courses Mandatory for this Role : </b></Form.Label>
                                                {roleDetailOptions && roleDetailOptions.course_details ? (
                                                    roleDetailOptions.course_details.map((data, id) => {
                                                        return <ul key={id}>
                                                            <li><b>{data.id}</b> - {data.details}</li>
                                                        </ul>
                                                    })
                                                ) : (

                                                    <p>-</p>)
                                                }
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                                    <Form.Label style={{ display: "block" }}><b>Present Courses for this Role : </b></Form.Label>
                                                    -
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                    </Form.Group> : <div></div>}



                                </Form>

                            </Modal.Body>
                            <Modal.Footer>

                                <Button variant="secondary" onClick={handleClosePosition}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={savePositionDetail}  >
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div ref={ref} style={{ overflowY: "scroll", height: "90vh", overflowX: "auto" }}>
                        <Table striped bordered hover id="table-id" className='table-responsive' style={{ width: `${editTableWidth}` }}>
                            <thead style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: "98" }}>
                                <th style={{ width: `${editRowWidth}`, textAlign: "center", maxWidth: "5vh", left: "0px", backgroundColor: "rgb(255 255 255)", zIndex: "99", position: "sticky" }}>

                                    <Row style={{ justifyContent: "center" }}>
                                        <Col>
                                            <FormControl sx={{ width: "100%" }} size="small">
                                                <InputLabel id="demo-select-small-label">Courses</InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={selectedHomeType.value}
                                                    label="Courses">
                                                    {homesType?.map((item) => {
                                                        return <MenuItem value={item.value} onClick={() => handleHomeTypeChange(item)}>{item.value}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col>
                                            <FormControl sx={{ width: "100%" }} size="small">
                                                <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectedArchiveType.name}
                                                    label="Roles">
                                                    {archiveType?.map((item) => {
                                                        return <MenuItem value={item.name} onClick={() => handleArchiveTypeChange(item)}>{item.name}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Paper component="form" variant="outlined"
                                                sx={{ display: 'flex', marginBottom: "2px", alignItems: 'center' }}>


                                                <Col >
                                                    <Tooltip variant="contained" title="Scroll Up">
                                                        <IconButton color="primary" onClick={() => scroll(-100, 'vertical')} aria-label="directions-up">
                                                            <KeyboardDoubleArrowUpOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Col>
                                                <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                                                <Col >
                                                    <Tooltip variant="contained" title="Scroll Right">
                                                        <IconButton color="primary" onClick={() => scroll(100, 'horizontal')} aria-label="directions-right">
                                                            <KeyboardDoubleArrowRightOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Col>
                                                <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                                                <Col >
                                                    <Tooltip variant="contained" title="Scroll Down">
                                                        <IconButton color="primary" onClick={() => scroll(100, 'vertical')} aria-label="directions-down">
                                                            <KeyboardDoubleArrowDownOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Col>
                                                <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                                                <Col>
                                                    <Tooltip variant="contained" title="Scroll Left">
                                                        <IconButton color="primary" onClick={() => scroll(-100, 'horizontal')} aria-label="directions-left">
                                                            <KeyboardDoubleArrowLeftOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Col>
                                            </Paper>
                                        </Col>
                                    </Row>
                                    <Typography variant='subtitle1' gutterBottom> Course Title</Typography>
                                </th>

                                {roleDetails?.map((data, id) => {
                                    return <th key={id} style={{ width: "3vh", maxWidth: "5vh" }}>

                                        {/* <p>
                    <Button variant="warning" onClick={(e) => {
                        createRole(e, data);
                    }} style={{  backgroundColor: "#ffc107" }} className="btn-sm">Duplicate Template</Button>
                </p> */}
                                        {/* <Modal show={showOption} onHide={handleCloseOption}>
                    <Modal.Header closeButton>
                        <Modal.Title>Duplicate Template</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                                <Form.Label>Enter the Role Name</Form.Label>
                                <Form.Control type="text"
                                    onChange={(e) => {
                                        addRoleText(e, 'hm1');
                                    }}
                                />

                            </Form.Group>

                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseOption} >
                            Close
                        </Button>
                        <Button variant="primary" onClick={getNewRole} >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal> */}

                                        <p><Form className='text-center'>
                                            <Form.Group className="sm-2 col-xs-6" controlId="formBasicEmail">
                                                <Form.Label><Typography variant='subtitle1'>Role Status</Typography></Form.Label>
                                                <br></br>
                                                {/* {(console.log("seee archivedStates---->",homeArchivedStates,trainData[data.role_id],data.role_id))} */}
                                                {trainData[data.role_id] != undefined && trainData[data.role_id] == "False" ?

                                                    <ButtonGroup>
                                                        <ToggleButton
                                                            id={`radio11-${id}`}
                                                            type="radio"
                                                            variant={(homeArchivedStates[data.role_id] == 'True' || homeArchivedStates[data.role_id] == true) ? 'danger' : 'outline-danger'}
                                                            name={`radio1-${id}`}
                                                            value='True'
                                                            size="sm"
                                                            disabled

                                                            checked={homeArchivedStates[homeArchivedStates[data.role_id]]}
                                                            onChange={(e) => { changeHomeArchiveStatus(e, data.role_id) }}
                                                        >
                                                            Archived
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            id={`radio21-${id}`}
                                                            type="radio"
                                                            variant={(homeArchivedStates[data.role_id] == 'True' || homeArchivedStates[data.role_id] == true) ? 'outline-success' : 'success'}
                                                            name={`radio2-${id}`}
                                                            value='False'
                                                            size="sm"
                                                            disabled
                                                            checked={homeArchivedStates[homeArchivedStates[data.role_id]]}
                                                            onChange={(e) => { changeHomeArchiveStatus(e, data.role_id) }}


                                                        >
                                                            Active
                                                        </ToggleButton>
                                                    </ButtonGroup>
                                                    :
                                                    <ButtonGroup>
                                                        <ToggleButton
                                                            id={`radio11-${id}`}
                                                            type="radio"
                                                            variant={(homeArchivedStates[data.role_id] == 'True' || homeArchivedStates[data.role_id] == true) ? 'danger' : 'outline-danger'}
                                                            name={`radio1-${id}`}
                                                            value='True'
                                                            size="sm"

                                                            checked={homeArchivedStates[homeArchivedStates[data.role_id]]}
                                                            onChange={(e) => { changeHomeArchiveStatus(e, data.role_id) }}
                                                        >
                                                            Archived
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            id={`radio21-${id}`}
                                                            type="radio"
                                                            variant={(homeArchivedStates[data.role_id] == 'True' || homeArchivedStates[data.role_id] == true) ? 'outline-success' : 'success'}
                                                            name={`radio2-${id}`}
                                                            value='False'
                                                            size="sm"
                                                            checked={homeArchivedStates[homeArchivedStates[data.role_id]]}
                                                            onChange={(e) => { changeHomeArchiveStatus(e, data.role_id) }}


                                                        >
                                                            Active
                                                        </ToggleButton>
                                                    </ButtonGroup>}

                                            </Form.Group>
                                        </Form></p>
                                        <Modal style={{ width: "50%", left: "25%", top: "20%" }} show={showArchiveWarning} onHide={handleCloseArchiveWarning}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Warning</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <h5>Are you sure you want to change the Archive Status for this role?</h5>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCloseArchiveWarning}>
                                                    Cancel
                                                </Button>
                                                <Button variant="primary" onClick={saveArchiveStatus}>
                                                    Yes
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Typography variant="subtitle1" style={{ textAlign: "center", cursor: "pointer" }} onClick={(e) => { handleShowCheckListRoleDetails(e, data.home_id, data.role_id); }}>{data.role_name}</Typography>

                                        <p>

                                            <Modal show={roleDetailshow} onHide={handleroleDetailClose} style={{ width: "50%", left: "20%" }}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Role Details</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {dispRoleDetail ?
                                                        <div>{dispRoleDetail.role_details}</div> : <div></div>

                                                    }


                                                </Modal.Body>
                                                <Modal.Footer>
                                                    {
                                                        (!sessionStorage.getItem('copyRoleObj')) ? <div></div> :
                                                            <Button variant="warning" onClick={handleOverWriteRole}>OverWrite Role Template</Button>
                                                    }

                                                    <Button variant="secondary" onClick={handleroleDetailClose} >
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal></p>

                                    </th>
                                })
                                }


                            </thead>
                            <tbody>
                                {courseList?.map((courses, id) => {

                                    return <tr key={id} >
                                        <td style={{ position: "sticky", left: "0px", backgroundColor: "rgb(250 246 246)", width: "10vh", zIndex: "97" }}><b style={{ cursor: "pointer" }} onClick={(e) => {
                                            getMicrodetails(e, courses);
                                        }}>{courses.title}({courses.courseID})</b><div>

                                                <Modal show={MicroCredShow} onHide={handleCloseMicroCred}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>View MicroCred Details</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <h5><b>Title</b> : {dispMicroCred.title}</h5>
                                                        <h5><b>Description</b> : {dispMicroCred.description}</h5>
                                                        <h5><b>Validity Duration</b> : {dispMicroCred.validity_duration}</h5>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div></td>
                                        {roleDetails?.map((data, _id) =>
                                            (globalCourseRole[data.role_id]?.includes(courses.courseID)) ?
                                                <td key={_id} style={{ borderRight: "1px solid #000", borderLeft: "1px solid #000", width: "5vh" }}>


                                                    {trainData[data.role_id] != undefined && trainData[data.role_id] == "False" ? <input
                                                        type="checkbox" className='yes' disabled style={{ transform: "scale(2)", marginLeft: "50%" }}
                                                        checked={(roleDetails[_id].course_details.find(item =>
                                                            item.id == courseList[id].courseID
                                                        ) != undefined)} onClick={(e) => {
                                                            handleCheckOnChange(e, courses, data, _id);
                                                        }}
                                                    /> : <input
                                                        type="checkbox" className='yes' style={{ transform: "scale(2)", marginLeft: "50%" }}
                                                        checked={(roleDetails[_id].course_details.find(item =>
                                                            item.id == courseList[id].courseID
                                                        ) != undefined)} onChange={(e) => {
                                                            handleCheckOnChange(e, courses, data, _id);
                                                        }}
                                                    />}
                                                </td>
                                                :
                                                <td key={_id} style={{ borderRight: "1px solid #000", borderLeft: "1px solid #000", width: "5vh" }}>
                                                    <input
                                                        type="checkbox" className='no' style={{ transform: "scale(2)", marginLeft: "50%" }}
                                                        checked={(roleDetails[_id].course_details.find(item =>
                                                            item.id == courseList[id].courseID
                                                        ) != undefined)} onChange={(e) => {
                                                            handleCheckOnChange(e, courses, data, _id);
                                                        }} />
                                                </td>



                                        )}

                                    </tr>
                                })}

                            </tbody>

                        </Table>
                    </div>
                    <br></br>

                    {/* <Button variant='warning' style={{margin:"1%"}} onClick={(e) => {
        { exportInExcel() }
    }}>Export to Excel</Button> */}
                </div>


            </Row>
        </div>
    );
}
